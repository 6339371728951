var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[(!_vm.isMobile || !_vm.$store.state.popup.id)?_c('div',{staticClass:"wrapper illustration illustration_form"},[_c('Backlink',{attrs:{"title":_vm.title,"backlink":_vm.backlink}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content__container"},[_c('p',{staticClass:"promo"},[_vm._v(_vm._s(_vm._f("localize")("reg_text")))]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler($event)}}},[_c('div',{staticClass:"form__group"},[_c('v-text-field',{ref:"name",class:{ invalid: _vm.$v.name.$dirty && !_vm.$v.name.required },attrs:{"label":_vm._f("localize")('reg_label_1'),"placeholder":_vm._f("localize")('reg_placeholder_1'),"hint":_vm._f("localize")((_vm.$v.name.$dirty && !_vm.$v.name.required
                  ? 'form_reqiered'
                  : 'empty')),"outlined":""},on:{"input":function($event){return _vm.checkForm(_vm.$v.name)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"form__group"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask.hide-on-empty",value:(_vm.phoneMask),expression:"phoneMask",modifiers:{"hide-on-empty":true}}],ref:"phone",staticClass:"v-input-phone",class:{invalid: _vm.$v.phone.$dirty && (!_vm.$v.phone.required || !_vm.$v.phone.minLength)},attrs:{"type":"tel","label":_vm._f("localize")('reg_phone_number'),"placeholder":"050 123 4567","hint":_vm._f("localize")((_vm.$v.phone.$dirty && !_vm.$v.phone.required ? 'form_reqiered_number' : 'empty')),"outlined":""},on:{"input":function($event){return _vm.checkForm(_vm.$v.phone)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-text-field',{ref:"email",class:{
                invalid:
                  (_vm.$v.email.$dirty && !_vm.$v.email.required) ||
                  (_vm.$v.email.$dirty && !_vm.$v.email.email),
              },attrs:{"type":"email","label":_vm._f("localize")('reg_email'),"placeholder":"name@bankimonline.com","hint":_vm._f("localize")(_vm.hintArray(
                _vm.$v.email, [
                  {rule: _vm.$v.email.required, message: 'form_reqiered_email'},
                  {rule: _vm.$v.email.alpha, message: 'form_wrong_abc_email'},
                  {rule: _vm.$v.email.email, message: 'form_wrong_email'}
                ]
              )),"outlined":""},on:{"input":function($event){return _vm.checkForm(_vm.$v.email)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-checkbox',{class:{ invalid: _vm.$v.banks_cb.$dirty && _vm.$v.banks_cb.$invalid },attrs:{"hide-details":"","on-icon":"icon-ckeckbox-on","off-icon":"icon-ckeckbox-off"},on:{"change":function($event){return _vm.checkForm(_vm.$v.banks_cb)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('input',{ref:"banks_cb",attrs:{"type":"hidden"}}),_c('router-link',{attrs:{"to":{ name: 'ApproveTiming', params: { nextlink: _vm.route } }}},[_vm._v(_vm._s(_vm._f("localize")("reg_checkbox_1_1")))]),_vm._v(" "+_vm._s(_vm._f("localize")("and"))+" "),_c('router-link',{attrs:{"to":{ name: 'BankPermission', params: { nextlink: _vm.route } }}},[_vm._v(_vm._s(_vm._f("localize")("reg_checkbox_1_2")))])]},proxy:true}],null,false,358167419),model:{value:(_vm.banks_cb),callback:function ($$v) {_vm.banks_cb=$$v},expression:"banks_cb"}}),_c('v-checkbox',{attrs:{"hide-details":"","on-icon":"icon-ckeckbox-on","off-icon":"icon-ckeckbox-off"},on:{"change":function($event){return _vm.checkForm(_vm.$v.sale)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('input',{ref:"sale",attrs:{"type":"hidden"}}),_vm._v(" "+_vm._s(_vm._f("localize")("reg_checkbox_2"))+" ")]},proxy:true}],null,false,3733140448),model:{value:(_vm.sale),callback:function ($$v) {_vm.sale=$$v},expression:"sale"}}),_c('div',{staticClass:"error__container error__container_button"},[(_vm.error)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm._f("localize")(_vm.error)))]):_vm._e(),(!_vm.success && _vm.check_form)?_c('p',{staticClass:"error"},[_vm._v(_vm._s(_vm._f("localize")('field_required')))]):_vm._e()]),_c('button',{staticClass:"button button__form",class:{ button__form_disabled: !_vm.success },attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm._f("localize")("form_next"))+" ")])],1)])])],1):_vm._e(),_c('PopupText')],1)}
var staticRenderFns = []

export { render, staticRenderFns }