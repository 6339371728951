<template>
  <div class="form">
    <div class="wrapper illustration illustration_form" v-if="!isMobile || !$store.state.popup.id">
      <Backlink :title="title" :backlink="backlink" />
      <div class="content">
        <div class="content__container">
          <p class="promo">{{ "reg_text" | localize }}</p>
          <form @submit.prevent="submitHandler">
            <div class="form__group">
              <v-text-field
                v-model="name"
                @input="checkForm($v.name)"
                :class="{ invalid: $v.name.$dirty && !$v.name.required }"
                :label="'reg_label_1' | localize"
                :placeholder="'reg_placeholder_1' | localize"
                :hint="
                  ($v.name.$dirty && !$v.name.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                ref="name"
                outlined
              ></v-text-field>
            </div>

            <div class="form__group">
              <v-text-field
                v-model="phone"
                type="tel"
                v-mask.hide-on-empty="phoneMask"
                @input="checkForm($v.phone)"
                class="v-input-phone"
                :class="{invalid: $v.phone.$dirty && (!$v.phone.required || !$v.phone.minLength)}"
                :label="'reg_phone_number' | localize"
                placeholder="050 123 4567"
                :hint="($v.phone.$dirty && !$v.phone.required ? 'form_reqiered_number' : 'empty') | localize"
                ref="phone"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="email"
                type="email"
                @input="checkForm($v.email)"
                :class="{
                  invalid:
                    ($v.email.$dirty && !$v.email.required) ||
                    ($v.email.$dirty && !$v.email.email),
                }"
                :label="'reg_email' | localize"
                placeholder="name@bankimonline.com"
                :hint="hintArray(
                  $v.email, [
                    {rule: $v.email.required, message: 'form_reqiered_email'},
                    {rule: $v.email.alpha, message: 'form_wrong_abc_email'},
                    {rule: $v.email.email, message: 'form_wrong_email'}
                  ]
                ) | localize"
                ref="email"
                outlined
              ></v-text-field>
            </div>

            <v-checkbox
              v-model="banks_cb"
              v-on:change="checkForm($v.banks_cb)"
              hide-details
              on-icon="icon-ckeckbox-on"
              off-icon="icon-ckeckbox-off"
              :class="{ invalid: $v.banks_cb.$dirty && $v.banks_cb.$invalid }"
            >
              <template v-slot:label>
                <input type="hidden" ref="banks_cb" />
                <router-link :to="{ name: 'ApproveTiming', params: { nextlink: route } }">{{ "reg_checkbox_1_1" | localize }}</router-link>
                {{ "and" | localize }}
                <router-link :to="{ name: 'BankPermission', params: { nextlink: route } }">{{ "reg_checkbox_1_2" | localize }}</router-link>
              </template>
            </v-checkbox>

            <v-checkbox
              v-model="sale"
              v-on:change="checkForm($v.sale)"
              hide-details
              on-icon="icon-ckeckbox-on"
              off-icon="icon-ckeckbox-off"
            >
              <template v-slot:label>
                <input type="hidden" ref="sale" />
                {{ "reg_checkbox_2" | localize }}
              </template>
            </v-checkbox>

            <div class="error__container error__container_button">
              <p class="error" v-if="error">{{ error | localize }}</p>
              <p class="error" v-if="!success && check_form">{{ 'field_required' | localize }}</p>
            </div>

            <button type="submit" class="button button__form" :class="{ button__form_disabled: !success }">
              {{ "form_next" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
    <PopupText />
  </div>
</template>

<script>
import { email, required, minLength } from 'vuelidate/lib/validators'
import Backlink from '@/components/base/Backlink.vue'
import PopupText from '@/components/PopupText.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Registration',
  components: { Backlink, PopupText },
  mixins: [formMixin, commonMixin],
  props: {
    title: { type: String, default: 'registration' },
    service: { type: String, required: true },
    nextlink: { type: String, default: '/' },
    backlink: { type: String, default: '/' },
    approved: { type: Boolean, default: false }
  },
  data: () => ({
    textId: 1,
    phoneMask: [/0/, /5|7/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    banks_cb: false,
    sale: false,
    name: null,
    phone: null,
    email: null,
    error: null,
    route: null
  }),
  validations: {
    name: { required },
    phone: { required, minLength: minLength(12) },
    email: {
      required,
      email,
      alpha: val => /^[a-z]/i.test(val)
    },
    banks_cb: {
      checked () {
        return this.banks_cb || false
      }
    }
  },
  created () {
    const user = this.$cookies.get('user') || {}
    this.name = user.name || null
    this.phone = user.phone || null
    this.email = user.email || null
    this.route = this.$router.currentRoute

    // Подсказка
    this.$store.commit('togglePopupText', { id: 12, withTitle: false, type: 'popup_info' })
  },
  methods: {
    submitHandler () {
      this.check_form = true
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      this.$store.commit('loading', true)
      this.error = null

      if (this.$store.state.user) {
        // Пользователь зарегистрирован - обновление данных
        this.axios
          .put('users/' + this.$store.state.user.id, {
            name: this.name,
            phone: this.phone,
            email: this.email
          })
          .then((response) => {
            this.$store.commit('loading', false)
            this.saveUser(response.data, this.$store.state.user.token)
            this.$router.push(this.nextlink)
          })
          .catch((error) => { this.catchUserError(error) })
        this.makeService(this.$store.state.user.id)
      } else {
        // Первичная регистрация
        this.axios
          .post('registration', {
            name: this.name,
            phone: this.phone,
            email: this.email,
            password: this.password(),
            device_name: navigator.userAgent
          })
          .then((response) => {
            this.saveUser(response.data)
            this.makeService(response.data.id)
            // Создание контейнера параметров пользователя
            this.axios.post('user_params', { user_id: response.data.id })
            this.$store.commit('loading', false)
            this.$router.push(this.nextlink)
          })
          .catch((error) => { this.catchUserError(error) })
      }
    },
    makeService (userId) {
      // Создание услуги
      this.axios.post('user_service', {
        user_id: userId,
        service_id: this.$store.state.services[this.service],
        status: this.$store.state.service_status
      }).catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
      // Создание информации об услуге
      this.axios.post('service_info', {
        user: userId,
        service: this.$store.state.services[this.service]
      }).catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    },
    catchUserError (error) {
      const errors = error.response.data.error || { error: ['server_error'] }

      for (const [key, value] of Object.entries(errors)) {
        switch (key) {
          case 'email': {
            this.error = 'form_taken_email'
            break
          }
          default: {
            this.error = value[0]
            break
          }
        }
      }

      this.$store.commit('loading', false)
      this.$scrollTo('.error__container', 300, { offset: -400 })
    },
    saveUser (data, token = null) {
      const userData = {
        id: data.id,
        name: data.name,
        phone: data.phone,
        photo: data.photo,
        email: data.email,
        token: token || data.token
      }
      this.$store.commit('saveUser', userData)
    }
  }
}
</script>
